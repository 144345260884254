<template>
  <div>
    <div :key="order.id" class="account__orders-item">
      <div class="item-left">
        <span class="order-num">ORDER #{{ order.id }}</span>
        <span class="order-price">{{ order.invoice.total }}€</span>
        <div class="order-more">
          <div class="order-delivery">
            <span v-if="order.is_pickup">
              {{ $t("cabinet.tags.pickup") }}
            </span>
            <span v-else>
              {{ $t("cabinet.tags.delivery") }}
            </span>
          </div>
          <div
            class="order-pizza"
            v-for="restaurant in order.restaurants"
            :key="restaurant"
          >
            <span>
              {{ restaurant }}
            </span>
          </div>
          <a
            class="order-download"
            v-bind:href="'/auth/pdf/generate/' + order.id"
            target="_blank"
          >
            <img alt="icon-load" class="icon-load" src="/img/icon-load.svg" />
            <span>Download invoice</span>
          </a>
        </div>
      </div>
      <div class="item-right">
        <button
          :class="['btn-heart', { active: isFavourite }]"
          @click.stop.prevent="toggleFavourite(order.id)"
        >
          <svg viewBox="0 0 44.008 44.008" xmlns="http://www.w3.org/2000/svg">
            <g id="icon_heart-favorite" transform="translate(1 1)">
              <path
                id="icon-coeur"
                d="M12.922,42.479A12.641,12.641,0,0,1,.7,30.1,11.731,11.731,0,0,1,12.478,18.163h5.778V12.637A11.731,11.731,0,0,1,30.033.7h.222A12.641,12.641,0,0,1,42.478,13.079L42.7,42.7Z"
                stroke="#ea564f"
                stroke-width="2"
                transform="translate(-0.7 -0.7)"
              />
            </g>
          </svg>
        </button>
        <button
          v-if="!Boolean(order._toggled)"
          @click="toggleOrder()"
          class="btn-arr-down"
        >
          <svg viewBox="0 0 38.475 22.857" xmlns="http://www.w3.org/2000/svg">
            <path
              id="chevron-down"
              d="M.435.48A1.48,1.48,0,0,1,.915.125a1.352,1.352,0,0,1,1.134,0A1.48,1.48,0,0,1,2.53.48L19.238,18.914,35.946.48a1.48,1.48,0,0,1,.481-.354,1.354,1.354,0,0,1,1.134,0,1.48,1.48,0,0,1,.481.354,1.655,1.655,0,0,1,.321.53,1.79,1.79,0,0,1,0,1.251,1.656,1.656,0,0,1-.321.53L20.285,22.378a1.479,1.479,0,0,1-.48.355,1.352,1.352,0,0,1-1.134,0,1.479,1.479,0,0,1-.48-.355L.435,2.791a1.653,1.653,0,0,1-.322-.53,1.789,1.789,0,0,1,0-1.251A1.653,1.653,0,0,1,.435.48Z"
              fill-rule="evenodd"
            />
          </svg>
        </button>
        <button v-else class="btn-arr-up" @click="toggleOrder()">
          <svg viewBox="0 0 38.475 22.857" xmlns="http://www.w3.org/2000/svg">
            <path
              id="chevron-down"
              d="M.435.48A1.48,1.48,0,0,1,.915.125a1.352,1.352,0,0,1,1.134,0A1.48,1.48,0,0,1,2.53.48L19.238,18.914,35.946.48a1.48,1.48,0,0,1,.481-.354,1.354,1.354,0,0,1,1.134,0,1.48,1.48,0,0,1,.481.354,1.655,1.655,0,0,1,.321.53,1.79,1.79,0,0,1,0,1.251,1.656,1.656,0,0,1-.321.53L20.285,22.378a1.479,1.479,0,0,1-.48.355,1.352,1.352,0,0,1-1.134,0,1.479,1.479,0,0,1-.48-.355L.435,2.791a1.653,1.653,0,0,1-.322-.53,1.789,1.789,0,0,1,0-1.251A1.653,1.653,0,0,1,.435.48Z"
              fill-rule="evenodd"
              transform="translate(38.475 22.857) rotate(180)"
            />
          </svg>
        </button>
      </div>
    </div>
    <template v-if="Boolean(order._toggled)">
      <div
        v-for="item in order.items"
        :key="item.cart_item.id"
        class="order__item"
      >
        <img
          :src="'/storage/' + item.cart_item.dish.image_menu"
          alt="logo-pizza"
          class="order__logo"
        />
        <div class="order__desc acc">
          <div class="order__title">
            <span>{{ item.cart_item.dish.restaurant }}</span>
          </div>
          <span class="order__name">
            {{ item.cart_item.dish.name }} {{ item.cart_item.dish.price_sell }}€
          </span>
          <span
            v-for="addition in item.cart_item.additions"
            :key="addition.id"
            class="order__taste"
          >
            {{ addition.name }}
            <template v-if="addition.price_sell > 0">
              + {{ addition.price_sell }}€
            </template>
          </span>
          <span class="order__text">
            {{ item.cart_item.comment }}
          </span>
          <div class="counter">
            <!--                      <button class="arr-down">&lt;</button>-->
            <input class="counter__num" type="text" value="1" />
            <!--                      <button class="arr-up">&gt;</button>-->
          </div>
          <!--                    <button class="order__btn-trash"></button>-->
        </div>
      </div>
      <div :key="order.id" class="account__orders-info">
        <div class="info-order">
          {{ $t("cart.subtotal") }}:
          <span>{{ order.invoice.subtotal }}</span>
        </div>
        <div class="info-order">
          {{ $t("cart.tips") }}:
          <span>{{ Math.round(order.invoice.tips * 100) }}%</span>
        </div>
        <div v-if="!order.is_pickup" class="info-order">
          {{ $t("cart.delivery_fee") }}:
          <span>{{ order.invoice.delivery_fee }}</span>
        </div>
        <div v-if="order.is_pickup" class="info-order">
          {{ $t("cart.pickup_discount") }}: <span>{{ order.invoice.pickup_discount*100 }} %</span>
        </div>
        <div class="info-total">
          {{ $t("cart.total") }}:
          <span>{{ order.invoice.total }}€</span>
        </div>
        <button class="cart__btn" @click="addToCart">
          {{ $t("dish.add_to_cart") }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["order", "cabinet", "gridView"],

  data() {
    return {
      incompatible: false,
      showPopup: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    isHardError() {
      return Object.prototype.hasOwnProperty.call(this.errors.errors, "dish");
    },
    isFavourite() {
      if (this.user !== null)
        return this.user.favourite_orders.some(fav => fav.id === this.order.id);
      else return false;
    }
  },

  methods: {
    ...mapActions("cart", ["addItem"]),
    ...mapActions("auth", ["me"]),
    toggleFavourite(index) {
      this.$http
        .post("/api/user/favourites", {
          resource: "order",
          resource_id: index.toString()
        })
        .then(() => this.me());
    },
    toggleOrder() {
      this.$set(
        this.order,
        "_toggled",
        this.order._toggled === undefined ? true : !this.order._toggled
      );
    },
    addToCart() {
      this.order.items.forEach(this.addToCartOne);
    },
    addToCartOne(value) {
      let topp = [];
      let topp_addit = [];
      let modif = [];
      let accomp = [];
      value.cart_item.additions.forEach(function(item) {
        switch (item.type) {
          case "toppings":
            topp.push(item.id);
            break;
          case "toppings_addit":
            topp_addit.push(item.id);
            break;
          case "modifications":
            modif.push(item.id);
            break;
          case "accompaniments":
            accomp.push(item.id);
            break;
        }
      });
      let value_send = {
        dish: value.cart_item.dish.id,
        quantity: value.cart_item.quantity,
        comment: value.cart_item.comment,
        toppings: topp,
        toppings_addit: topp_addit,
        modification: modif,
        accompaniment: accomp,
        period: value.cart_item.dish.primary_period
      };
      this.addItem(value_send)
        .then(() => {
          this.me();
        })
        .catch(error => (this.errors = error.response.data));
    }
  }
};
</script>
