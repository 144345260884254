<template>
  <div>
    <section id="favorits_slide">
      <div class="container">
        <h1 class="title__home">
          {{ $t("favourites.title") }}
        </h1>
      </div>
    </section>

    <div class="container">
      <div class="tabs_block">
        <a
          v-for="m in modes"
          :key="m"
          :class="['items__tabs', { active: m === mode }]"
          @click="mode = m"
        >
          {{ $t("favourites.modes." + m) }}
        </a>
      </div>
    </div>
    <section>
      <div class="container">
        <div class="mobail__tabs">
          <button
            id="mini_prod"
            :class="['mini_prod', { active: gridView === true }]"
            @click="gridView = true"
          ></button>
          <img alt="" src="/img/line.svg" />
          <button
            id="full_prod"
            :class="['full_prod', { active: gridView === false }]"
            @click="gridView = false"
          ></button>
        </div>
      </div>
    </section>
    <section id="favorits__page">
      <div class="container">
        <div class="row">
          <template v-if="mode === 'dishes'">
            <template v-for="dish in user.favourite_dishes">
              <DishCard :key="dish.id" :dish="dish" :grid-view="gridView" />
            </template>
          </template>
          <template v-if="mode === 'orders'">
            <template v-for="order in user.favourite_orders">
              <OrderCard :key="order.id" :grid-view="gridView" :order="order" />
            </template>
          </template>
          <template v-if="mode === 'restaurants'">
            <template v-for="restaurant in user.favourite_restaurants">
              <RestaurantCard
                :key="restaurant.id"
                :grid-view="gridView"
                :restaurant="restaurant"
              />
            </template>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DishCard from "@/components/DishCard";
import RestaurantCard from "@/components/RestaurantCard";
import OrderCard from "@/components/OrderCard";

export default {
  components: {
    DishCard,
    RestaurantCard,
    OrderCard
  },

  data() {
    return {
      gridView: true,
      modes: ["dishes", "orders", "restaurants"],
      mode: "dishes"
    };
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {},

  metaInfo() {
    return {
      title: this.$t("favourites.title")
    };
  }
};
</script>
